import {
  match as createMatchFn,
  type Match,
  type MatchFunction,
} from "path-to-regexp";
import { fetchQuery, loadQuery } from "react-relay";
import { getRequest } from "relay-runtime";
import { getCurrentUser } from "./Auth";
import { ForbiddenError, NotFoundError } from "./errors";
import {
  type Route,
  type RouterContext,
  type RouterResponse,
} from "./router.types";

const defaultSeo = {
  title: "Купить орехи и сухофрукты в Санкт-Петербурге",
  description:
    "Интернет-магазин орехов и сухофруктов. У нас вы можете купить орехи и сухофрукты на любой вкус недорого и по хорошей цене",
  keywords:
    "орехи, сухофрукты, купить орехи, купить сухофрукты, орехи в Санкт-Петербурге, сухофрукты в Санкт-Петербурге",
  viewPort:
    "width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=1",
  robots: "index, follow",
  canonicalURL: (ctx: RouterContext) => ctx.config.app.origin + ctx.path,
  image: "/logo.png",
  ogCanonicalURL: (ctx: RouterContext) => ctx.config.app.origin + ctx.path,
  ogTitle: "Купить орехи и сухофрукты в Санкт-Петербурге",
  ogDescription: "Интернет-магазин орехов и сухофруктов",
  ogType: "website",
  ogImage: "/logo.png",
};

const coalesce = (
  ctx: RouterContext,
  a: string | undefined,
  b: string | ((ctx: RouterContext) => string),
) => a ?? (typeof b === "function" ? b(ctx) : b);

/**
 * Converts the URL path string to a RegExp matching function.
 *
 * @see https://github.com/pillarjs/path-to-regexp
 */
const matchUrlPath: (
  pattern: string[] | string,
  path: string,
) => Match<{ [key: string]: string }> = (() => {
  const cache = new Map<string, MatchFunction<{ [key: string]: string }>>();
  return function matchUrlPath(pattern: string[] | string, path: string) {
    const key = Array.isArray(pattern) ? pattern.join("::") : pattern;
    let fn = cache.get(key);
    if (fn) return fn(path);
    fn = createMatchFn(pattern, { decode: decodeURIComponent });
    cache.set(key, fn);
    return fn(path);
  };
})();

async function resolveRoute(
  ctx: RouterContext,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  routes: readonly Route<any, any>[],
): Promise<RouterResponse> {
  try {
    // Find the first route matching the provided URL path string
    for (let i = 0, route; i < routes.length, (route = routes[i]); i++) {
      const match = matchUrlPath(route.path, ctx.path);

      if (!match) continue;

      ctx.params = match.params;

      // Prepare GraphQL query variables
      const variables =
        typeof route.variables === "function"
          ? route.variables(ctx)
          : route.variables
            ? route.variables
            : Object.keys(match.params).length === 0
              ? {}
              : match.params;

      // If `auth` variable is present in the route's GraphQL query
      // and the user's authentication state is not known yet, set it to true.
      if (route.query && typeof route.query !== "function") {
        const { operation } = getRequest(route.query);
        if (operation.argumentDefinitions.some((x) => x.name === "auth")) {
          variables.auth = getCurrentUser(ctx.relay) === undefined;
        }
      }

      // Fetch GraphQL query response and load React component in parallel
      const [component, data] = await Promise.all([
        route.component?.().then((x) => x.default),
        route.query &&
          (typeof route.query === "function"
            ? route.query(ctx)
            : route.useLoadQuery
              ? loadQuery(ctx.relay, route.query, variables, {
                  fetchPolicy: "store-or-network",
                })
              : // Had to change store-or-network mode to network-only because of problems with discounts reloading
                fetchQuery(ctx.relay, route.query, variables, {
                  fetchPolicy: "network-only",
                }).toPromise()),
      ]);

      // Check if the route requires an authenticated user
      if (route.authorize) {
        const user = getCurrentUser(ctx.relay);
        if (
          !user ||
          (typeof route.authorize === "function" && !route.authorize(user))
        ) {
          throw new ForbiddenError();
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response = route.response(data as any, ctx);

      if (response)
        return {
          component,
          ...{
            props: response.props,
            title: coalesce(ctx, response.title, defaultSeo.title),
            description: coalesce(
              ctx,
              response.description,
              defaultSeo.description,
            ),
            keywords: coalesce(ctx, response.keywords, defaultSeo.keywords),
            viewPort: coalesce(ctx, response.viewPort, defaultSeo.viewPort),
            robots: coalesce(ctx, response.robots, defaultSeo.robots),
            canonicalURL: coalesce(
              ctx,
              response.canonicalURL,
              defaultSeo.canonicalURL,
            ),
            image: coalesce(ctx, response.image, defaultSeo.image),
            ogCanonicalURL: coalesce(
              ctx,
              response.ogCanonicalURL,
              defaultSeo.ogCanonicalURL,
            ),
            ogTitle: coalesce(ctx, response.ogTitle, defaultSeo.ogTitle),
            ogDescription: coalesce(
              ctx,
              response.ogDescription,
              defaultSeo.ogDescription,
            ),
            ogType: coalesce(ctx, response.ogType, defaultSeo.ogType),
            ogImage: coalesce(ctx, response.ogImage, defaultSeo.ogImage),
          },
        };
    }

    throw new NotFoundError();
  } catch (err) {
    return {
      title:
        err instanceof NotFoundError ? "Page not found" : "Application error",
      error: err as Error,
    };
  }
}

export {
  resolveRoute,
  type Route,
  type RouterContext,
  type RouterResponse as RouteResponse,
};
